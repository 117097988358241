import type { Job } from "~/db/types";
import { BanknoteIcon, MapPinIcon } from "lucide-react";
import { salaryTimePeriodsEnum } from "~/utils/consts";
import { Link } from "@remix-run/react";
import { TypographyH3 } from "./ui/typography/h3";

export function RecentJobArticle({ job }: { job: Job }) {
  return (
    <Link to={`../job/${job.id}`}>
      <article className="p-2 px-4">
        <header className="flex justify-between">
          <TypographyH3 className="text-lime-800 font-bold hover:underline">
            {job.title}
          </TypographyH3>
        </header>
        <p className="mb-2">{job.schoolName}</p>
        <ul className="mb-1 flex gap-2">
          <li className="flex items-center">
            <MapPinIcon className="mr-2 text-lime-600" size={18} />
            <p>{job.location}</p>
          </li>
          <li className="flex items-center">
            <BanknoteIcon className="mr-2 text-lime-600" size={18} />
            <p>
              ₹{" "}
              {job.salary?.split("-")[0] === "0" || job.salary === ""
                ? "Salary Negotiable"
                : job.salary +
                  " " +
                  salaryTimePeriodsEnum[
                    job.salaryTimePeriod as keyof typeof salaryTimePeriodsEnum
                  ]}{" "}
            </p>
          </li>
        </ul>
      </article>
    </Link>
  );
}
