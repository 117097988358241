import { getAuth } from "@clerk/remix/ssr.server";
import type {
  HeadersFunction,
  LoaderFunction,
  V2_MetaFunction,
} from "@remix-run/cloudflare";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import Search from "~/components/search";
import { TypographyH2 } from "~/components/ui/typography/h2";
import { useCurrentUserType } from "~/root";
import type { UserTypes } from "~/utils/getUserType";
import { getUserType } from "~/utils/getUserType";
import cv from "../../public/images/cv.webp";
import jobOffer from "../../public/images/job-offer.webp";
import type { Job, JobActions } from "~/db/types";
import { RecentJobArticle } from "~/components/recentJobArticle";
import { Button } from "~/components/ui/button";
import { ArrowRight } from "lucide-react";
import { getRecentJobs } from "~/models/job.server";

export const meta: V2_MetaFunction = () => {
  return [
    {
      title:
        "Schools Jobs | India's 1st Privacy focused job portal dedicated for educational institutions.",
    },
    {
      name: "description",
      content:
        "India's 1st Privacy focused job portal dedicated for educational institutions. Search your dream job with ease. Simplest portal for recruiters",
    },
    {
      property: "og:url",
      content: "https://schooljobs.live/",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: "https://schooljobs.live/images/banner.png",
    },
    {
      property: "og:title",
      content:
        "Schools Jobs | India's 1st Privacy focused job portal dedicated for educational institutions.",
    },
    {
      property: "og:description",
      content:
        "India's 1st Privacy focused job portal dedicated for educational institutions. Search your dream job with ease. Simplest portal for recruiters",
    },
  ];
};

export const headers: HeadersFunction = () => ({
  "Cache-Control": "max-age=1, s-maxage=3600, stale-while-revalidate=59",
});

export interface Env {
  CLERK_SECRET_KEY: string;
  CLERK_PUBLISHABLE_KEY: string;
  CLERK_WEBHOOK_SIGNING_SECRET: string;
  MAILGUN_API_KEY: string;
  DATABASE_URL: string;
  DATABASE_AUTH_TOKEN: string;
  R2_CV_BUCKET: any;
  R2_IMG_BUCKET: any;
}

export const loader: LoaderFunction = async ({ request, context, params }) => {
  const { userId } = await getAuth(
    { request, context, params },
    {
      secretKey:
        context.env && (context.env as Env)["CLERK_SECRET_KEY"]
          ? (context.env as Env)["CLERK_SECRET_KEY"]
          : "",
    }
  );
  if (!userId) {
    // return redirect("/sign-in");
  }

  const userType = await getUserType(context, userId);

  const recentJobsData = await getRecentJobs(context);

  return { userType, recentJobsData };
};

export default function Index() {
  const { userType, recentJobsData } = useLoaderData<typeof loader>() as {
    userType: UserTypes;
    recentJobsData: (Job & JobActions)[];
  };

  const setUserType = useCurrentUserType((state) => state.setUserType);

  setUserType(userType);

  const navigate = useNavigate();

  return (
    <>
      <div className="mx-auto max-w-6xl">
        <div>
          <h1 className={"mb-6 text-left text-2xl"}>
            Search you next dream job with Schooljobs.live
          </h1>
          <Search />

          <section className="mt-10 sm:mt-12 -mx-4 sm:mx-0">
            <header className="sm:mb-4 flex justify-between px-4">
              <h2 className="text-lg font-semibold">Recent Jobs</h2>
              <Link to={"./search/jobs"} className="flex gap-2">
                View all <ArrowRight className="text-lime-400" />
              </Link>
            </header>

            <ul className="border-t border-lime-400 mt-5">
              {recentJobsData.length !== 0 &&
                recentJobsData.map((job) => (
                  <li
                    key={job.id + "job-id"}
                    className="border-b border-slate-300 hover:border-lime-400 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      navigate(`../job/${job.id}`);
                    }}
                  >
                    <RecentJobArticle job={job} />
                  </li>
                ))}
            </ul>

            <footer className="flex justify-center mt-10">
              <Link to={"./search/jobs"} className="flex gap-2">
                <Button className="flex gap-2" variant={"outline"} size={"lg"}>
                  More Jobs <ArrowRight className="text-lime-400" />
                </Button>
              </Link>
            </footer>
          </section>

          <div className="flex flex-col lg:flex-row w-full justify-evenly">
            <Link
              to="./candidate/cv"
              className="flex-1 border-2 border-lime-400 h-36 lg:mt-20 mt-10 rounded-lg sm:p-8 p-4"
            >
              <div className="flex justify-between items-center">
                <div>
                  <TypographyH2 className="mb-2">Upload Your CV</TypographyH2>
                  <p>Find your dream job</p>
                </div>

                <img src={cv} className="w-20" width={80} height={80} alt="" />
              </div>
            </Link>
            <Link
              to={"./recruiter/post-job"}
              className="flex-1 border-2 border-lime-400 h-36 lg:mt-20 mt-10 rounded-lg sm:p-8 p-4 lg:ml-4"
            >
              <div className="flex justify-between items-center">
                <div>
                  <TypographyH2 className="mb-2">Post your Job</TypographyH2>
                  <p>Find your perfect candidate</p>
                </div>

                <img
                  src={jobOffer}
                  className="w-20"
                  width={80}
                  height={80}
                  alt=""
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
