import { Form } from "@remix-run/react";

import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { BriefcaseIcon, MapPinIcon } from "lucide-react";

export default function Search() {
  return (
    <div className="lg:border-2 rounded border-lime-500">
      <Form action="./search/jobs">
        <div className="flex md:flex-row flex-col w-full lg:p-2 space-y-4 md:space-y-0">
          <div className="flex flex-col gap-2 flex-1 w-full">
            <div className="flex gap-2 lg:hidden">
              <BriefcaseIcon /> What
            </div>
            <Input
              name="t"
              className="h-12 lg:h-16 lg:rounded-none border-2 border-lime-500 lg:border-0 lg:border-r-2 lg:focus:outline-none"
              placeholder="Job title, keywords or company"
            />
          </div>

          <div className="flex flex-col gap-2 flex-1 w-full md:ml-4">
            <div className="flex gap-2 lg:hidden">
              <MapPinIcon /> Where
            </div>
            <Input
              name="l"
              className="h-12 lg:h-16 lg:rounded-none border-2 border-lime-500 lg:border-0 lg:focus:outline-none"
              placeholder="Town or State"
            />
          </div>
          <div>
            <div className="h-6 lg:hidden"></div>
            <Button
              className="text-lg font-bold text-white bg-lime-600 hover:bg-lime-800 h-12 mt-2 md:ml-4 md:w-36 w-full"
              variant="secondary"
              type="submit"
            >
              <MagnifyingGlassIcon className="mr-1 h-6 w-6" /> Search
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
